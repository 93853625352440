import React from "react"
import Layout from "../components/Layout/Layout"
import {Link} from "gatsby";
import {StaticImage} from "gatsby-plugin-image";
import Kontakt from "../components/Layout/Contact";
import CarouselReference from "../components/Components/CarouselReference";

export default function Spravait() {
    return (
        <Layout>
            <div className="headerBluePic mb-5">
                <div className="headerBlue_content">
                    <div className="container">
                        <div className="row pt-4">
                            <div className="col-12 col-lg-6 pt-5">
                                <h1 className="heading-border-bottom-white">Helios iNuvio</h1>
                                <h2 className="pt-1">Nasadíme Vám<br/> to nejlepší ERP</h2>
                                <p className="subHeadline pt-1">Díky ERP Helios iNuvio zefektivníte správu dat a řízení
                                    procesů. Pomůžeme vám zlepšit produktivita a konkurenceschopnost díky
                                    přizpůsobitelným funkcím systému a intuitivnímu ovládání.</p>
                            </div>
                            <div className="col-12 col-lg-6">
                                <div className="mx-auto pt-5 p-xxl-5">
                                    <StaticImage imgClassName={"it headerBlue_img"}
                                                 src="../assets/custom-style/images/ilustrace-03.svg"
                                                 alt={"Ilustrace"}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="subHeader pt-5 pb-5 text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-3 mb-3" data-sal="fade" data-sal-delay="300"
                             data-sal-easing="ease">
                            <div className="card-icon">
                                <StaticImage imgClassName={"it"}
                                             src="../assets/custom-style/images/icons/business.png"
                                             alt={"Ikona - obchod"}/>
                            </div>

                            <p className="pt-3">Helios iNuvio využívá <br className="d-md-inline d-none"/>4 500 <br
                                className="d-inline d-md-none"/>firem v<br className="d-md-inline d-none"/>ČR a SK
                            </p>
                        </div>

                        <div className="col-12 col-md-6 col-lg-3 mb-3" data-sal="fade" data-sal-delay="100"
                             data-sal-easing="ease">
                            <div className="card-icon">
                                <StaticImage imgClassName={"it"}
                                             src="../assets/custom-style/images/icons/documentation.webp"
                                             alt={"ikona - dokument"}/>
                            </div>

                            <p className="pt-3">Moderní ERP systém <br className="d-md-inline d-none"/>pro <br
                                className="d-inline d-md-none"/>řízení dat <br className="d-md-inline d-none"/>a procesů
                            </p>
                        </div>

                        <div className="col-12 col-md-6 col-lg-3 mb-3" data-sal="fade" data-sal-delay="200"
                             data-sal-easing="ease">
                            <div className="card-icon">
                                <StaticImage imgClassName={"it"} src="../assets/custom-style/images/icons/team.webp"
                                             alt={"ikona - tým"}/>
                            </div>

                            <p className="pt-3">Správa vašeho podnikání <br className="d-md-inline d-none"/>nikdy <br
                                className="d-inline d-md-none"/>nebyla <br className="d-md-inline d-none"/>snazší
                            </p>
                        </div>

                        <div className="col-12 col-md-6 col-lg-3 mb-3" data-sal="fade" data-sal-delay="300"
                             data-sal-easing="ease">
                            <div className="card-icon">
                                <StaticImage imgClassName={"it"} src="../assets/custom-style/images/icons/plan.webp"
                                             alt={"ikona - plán"}/>
                            </div>

                            <p className="pt-3">Automatizujte a <br className="d-md-inline d-none"/>optimalizujte <br
                                className="d-inline d-md-none"/>své <br className="d-md-inline d-none"/>podnikání
                            </p>
                        </div>
                    </div>
                </div>
            </div>


            {/* <div className="subHeader pt-5 pb-5 text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-3 mb-3" data-sal="fade" data-sal-delay="300" data-sal-easing="ease">
                            <div className="card-icon">
                                <StaticImage imgClassName={"it"} src="../assets/custom-style/images/icons/clock.webp" />
                            </div>
                            <p className="pt-3">Pracujte efektivně <br className={"desktop-break"} /> díky skvěle <br className={"desktop-break"} />vyladěnému IT</p>
                        </div>
                        <div className="col-12 col-md-6 col-lg-3 mb-3" data-sal="fade" data-sal-delay="300" data-sal-easing="ease">
                            <div className="card-icon">
                                <StaticImage imgClassName={"device"}
                                    src="../assets/custom-style/images/icons/computer.webp" />
                            </div>
                            <p className="pt-3">Využijte pro práci <br className={"desktop-break"} /> zařízení, které vám <br className={"desktop-break"} />vyhovují</p>
                        </div>
                        <div className="col-12 col-md-6 col-lg-3 mb-3" data-sal="fade" data-sal-delay="300" data-sal-easing="ease">
                            <div className="card-icon">
                                <StaticImage imgClassName={"communication"}
                                    src="../assets/custom-style/images/icons/communication.webp" />
                            </div>
                            <p className="pt-3">Zjednodušte <br className={"desktop-break"} />komunikaci a&nbsp;přístup <br className={"desktop-break"} />k&nbsp;informacím</p>
                        </div>
                        <div className="col-12 col-md-6 col-lg-3 mb-3" data-sal="fade" data-sal-delay="300" data-sal-easing="ease">
                            <div className="card-icon">
                                <StaticImage imgClassName={"protection"}
                                    src="../assets/custom-style/images/icons/protection.webp" />
                            </div>
                            <p className="pt-3">Chraňte svá data <br className={"desktop-break"} />a&nbsp;mějte klidné <br className={"desktop-break"} />spaní</p>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className="bgGrey pt-5 pb-5">
                <div className="container">
                    <h2 className="text-center">ERP: Zlepšete své podnikání</h2>

                    <p className="text-center pt-5">Helios iNuvio je řešením pro centralizaci a správu různých oblastí,
                        jako jsou finance, personalistika, <br
                            className="d-none d-lg-inline d-xl-none"/>obchod, výroba a mnoho dalších. <br
                            className="d-none d-xl-inline"/>
                        Díky intuitivnímu ovládání <br className="d-none d-lg-inline d-xl-none"/>a snadnému použití
                        můžete spravovat své
                        procesy snadno a rychle.</p>
                    <div className="digiCards pt-5">
                        <div className="row">
                            <div className="col-12 col-md-6 col-lg-3 mb-3" data-sal="fade" data-sal-delay="100"
                                 data-sal-easing="ease">
                                <div className="card   h-100">
                                    <div className="card-body">
                                        <div className="card-icon">
                                            <StaticImage imgClassName={"time"}
                                                         src="../assets/custom-style/images/icons/time.webp"
                                                         alt={"ikona - čas"}
                                            />
                                        </div>
                                        <h5 className="card-title pt-3">Sledování dat a informací</h5>
                                        <p className="card-text d-none d-xl-block">Sbírejte, analyzujte a vizualizujte
                                            data z celého vašeho podnikání. Budete mít přesnější informace o svých
                                            výkonech, což vám umožní lepší plánování a rozhodování. Sledování dat Vám
                                            také pomůže odhalit a řešit slabá místa.</p>
                                        <p className="card-text d-block d-xl-none">Sbírejte, analyzujte a vizualizujte
                                            data z celého vašeho podnikání. Budete mít přesnější informace o svých
                                            výkonech, což vám umožní lepší plánování a rozhodování. Sledování dat Vám
                                            také pomůže odhalit a řešit slabá místa.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-3 mb-3" data-sal="fade" data-sal-delay="300"
                                 data-sal-easing="ease">
                                <div className="card  h-100">
                                    <div className="card-body">
                                        <div className="card-icon">
                                            <StaticImage imgClassName={"team"}
                                                         src="../assets/custom-style/images/icons/team.webp"
                                                         alt={"ikona - tým"}
                                            />
                                        </div>
                                        <h5 className="card-title pt-3">Nižší náklady, vyšší zisk</h5>
                                        <p className="card-text d-none d-xl-block">Přestaňte řešit rutinní úkoly,
                                            tisknout zbytečné papíry a komunikovat chaoticky. Snižte náklady
                                            prostřednictvím automatizace procesů, což eliminuje chyby, umožňuje
                                            efektivnější využití zdrojů a vede k větší ziskovosti.</p>
                                        <p className="card-text d-block d-xl-none">Přestaňte řešit rutinní úkoly,
                                            tisknout zbytečné papíry a komunikovat chaoticky. Snižte náklady
                                            prostřednictvím automatizace procesů, což eliminuje chyby, umožňuje
                                            efektivnější využití zdrojů a vede k větší ziskovosti.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-3 mb-3" data-sal="fade" data-sal-delay="500"
                                 data-sal-easing="ease">
                                <div className="card  h-100">
                                    <div className="card-body">
                                        <div className="card-icon">
                                            <StaticImage imgClassName={"office"}
                                                         src="../assets/custom-style/images/icons/office.webp"
                                                         alt={"ikona - kancelář"}
                                            />
                                        </div>
                                        <h5 className="card-title pt-3">Vyšší spokojenost zákazníků</h5>
                                        <p className="card-text d-none d-xl-block">V jednom přehledném systému sledujte
                                            a spravujte stav objednávek, distribuci a reklamace, stejně tak jako plnění
                                            všech závazků vůči zákazníkům a komunikaci. Zajistíte si tak jejich vyšší
                                            spokojenost a loajalitu.
                                        </p>
                                        <p className="card-text d-block d-xl-none">V jednom přehledném systému sledujte
                                            a spravujte stav objednávek, distribuci a reklamace, stejně tak jako plnění
                                            všech závazků vůči zákazníkům a komunikaci. Zajistíte si tak jejich vyšší
                                            spokojenost a loajalitu.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-3 mb-3" data-sal="fade" data-sal-delay="400"
                                 data-sal-easing="ease">
                                <div className="card  h-100">
                                    <div className="card-body">
                                        <div className="card-icon">
                                            <StaticImage imgClassName={"safety"}
                                                         src="../assets/custom-style/images/icons/lock.webp"
                                                         alt={"ikona - zámek"}
                                            />
                                        </div>
                                        <h5 className="card-title pt-3">Modularita ERP systému</h5>
                                        <p className="card-text d-none d-xl-block">Reagujte rychleji a jednodušeji na
                                            měnící se podmínky ve Vašem podnikání, požadavky zákazníků či změny v
                                            legislativě. Přidávejte či odebírejte moduly ERP systému dle potřeby, nebo
                                            využijte možností integrace díky API.</p>
                                        <p className="card-text d-block d-xl-none">Reagujte rychleji a jednodušeji na
                                            měnící se podmínky ve Vašem podnikání, požadavky zákazníků či změny v
                                            legislativě. Přidávejte či odebírejte moduly ERP systému dle potřeby, nebo
                                            využijte možností integrace díky API.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>


            <div className="bgWhite pt-5 pb-5">
                <div className="container">
                    <h2 className="text-center">Helios iNuvio Vám vyřeší mnoho oblastí</h2>
                    <p className="text-center pt-5">Díky nasazení ERP systému uděláte ve svém podnikání obrovský krok ke
                        zlepšení a zefektivnění celkového fungování, což zahrnuje výkonnost, komunikaci mezi odděleními
                        a integraci různých procesů. Naše znalosti a zkušenosti v oblasti analýzy, implementace a
                        podpory vám pomohou zvládnout tento přechod bez problémů.</p>
                    <div className="digiCards">
                        <div className="row pt-5">
                            <div className="col-12 col-md-6 col-lg-3 mb-4" data-sal="fade" data-sal-delay="200"
                                 data-sal-easing="ease">
                                <div className="card  h-100">
                                    <div className="card-body">
                                        <div className="card-icon">
                                            <StaticImage imgClassName={"helpdesk"}
                                                         src="../assets/custom-style/images/icons/helpdesk.webp"
                                                         alt={"ikona - helpdesk"}
                                            />
                                        </div>
                                        <h5 className="card-title pt-3">Zefektivnění financí</h5>
                                        <p className="card-text">Ušetřete čas a peníze díky automatizaci účetnictví,
                                            rozpočtování a finančního plánování. Zlepšíte tak efektivitu a snížíte
                                            chybovost.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-3 mb-4" data-sal="fade" data-sal-delay="500"
                                 data-sal-easing="ease">
                                <div className="card  h-100">
                                    <div className="card-body">
                                        <div className="card-icon">
                                            <StaticImage imgClassName={"catalog"}
                                                         src="../assets/custom-style/images/icons/catalog.webp"
                                                         alt={"ikona - katalog"}
                                            />
                                        </div>
                                        <h5 className="card-title pt-3">Optimalizace skladu</h5>
                                        <p className="card-text">Snižte náklady na skladování a zvyšte efektivitu
                                            dodávek díky lepšímu řízení zásob. Optimalizovaný sklad vám ušetří čas a
                                            náklady.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-3 mb-4" data-sal="fade" data-sal-delay="100"
                                 data-sal-easing="ease">
                                <div className="card  h-100">
                                    <div className="card-body">
                                        <div className="card-icon">
                                            <StaticImage imgClassName={"documentation"}
                                                         src="../assets/custom-style/images/icons/documentation.webp"
                                                         alt={"ikona - dokumentace"}
                                            />
                                        </div>
                                        <h5 className="card-title pt-3">Zvýšení prodeje</h5>
                                        <p className="card-text">Získejte lepší přehled o zakázkách, zákaznících a
                                            tržních trendů pro lepší obchodní strategii. Zrychlíte reakci na změny na
                                            trhu.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-md-6 col-lg-3 mb-4" data-sal="fade" data-sal-delay="100"
                                 data-sal-easing="ease">
                                <div className="card  h-100">
                                    <div className="card-body">
                                        <div className="card-icon">
                                            <StaticImage imgClassName={"info"}
                                                         src="../assets/custom-style/images/icons/info.webp"
                                                         alt={"ikona - informace"}
                                            />
                                        </div>
                                        <h5 className="card-title pt-3">Zlepšení řízení lidí</h5>
                                        <p className="card-text">Zjednodušte nábor, plánování, sledování času a výplaty
                                            pro lepší řízení zaměstnanců. Zlepšíte pracovní prostředí a snížíte
                                            fluktuaci.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-6 col-lg-3 mb-4" data-sal="fade" data-sal-delay="500"
                                 data-sal-easing="ease">
                                <div className="card  h-100">
                                    <div className="card-body">
                                        <div className="card-icon">
                                            <StaticImage imgClassName={"skill"}
                                                         src="../assets/custom-style/images/icons/skill.webp"
                                                         alt={"ikona - skill"}
                                            />
                                        </div>
                                        <h5 className="card-title pt-3">Úspěšné projekty</h5>
                                        <p className="card-text">Zefektivněte řízení projektů a sledujte pokroky,
                                            alokací zdrojů a časové rámce. Zajistíte tak včasné a úspěšné dokončení
                                            projektů.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-3 mb-4" data-sal="fade" data-sal-delay="200"
                                 data-sal-easing="ease">
                                <div className="card  h-100">
                                    <div className="card-body">
                                        <div className="card-icon">
                                            <StaticImage imgClassName={"plan"}
                                                         src="../assets/custom-style/images/icons/plan.webp"
                                                         alt={"ikona - plan"}
                                            />
                                        </div>
                                        <h5 className="card-title pt-3">Efektivita výroby</h5>
                                        <p className="card-text">Zlepšete produktivitu a kvalitu výrobních procesů
                                            pomocí pokročilých nástrojů pro sledování. Zrychlíte tak výrobu snížíte
                                            chybovost.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-3 mb-4" data-sal="fade" data-sal-delay="100"
                                 data-sal-easing="ease">
                                <div className="card  h-100">
                                    <div className="card-body">
                                        <div className="card-icon">
                                            <StaticImage imgClassName={"security"}
                                                         src="../assets/custom-style/images/icons/security.webp"
                                                         alt={"ikona - bezpečnost"}
                                            />
                                        </div>
                                        <h5 className="card-title pt-3">Spokojenost zákazníků</h5>
                                        <p className="card-text">Reagujte rychleji a přesněji při komunikaci se
                                            zákazníky díky integrovaným funkcím. Zvýšíte tak loajalitu a spokojenost
                                            zákazníků.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-3 mb-4" data-sal="fade" data-sal-delay="500"
                                 data-sal-easing="ease">
                                <div className="card  h-100">
                                    <div className="card-body">
                                        <div className="card-icon">
                                            <StaticImage imgClassName={"quality"}
                                                         src="../assets/custom-style/images/icons/quality.webp"
                                                         alt={"ikona - kvalita"}
                                            />
                                        </div>
                                        <h5 className="card-title pt-3">Strategické rozhodování</h5>
                                        <p className="card-text">Vytvářejte podrobné analýzy pro lepší porozumění vašemu
                                            podnikání. Můžete tak optimalizovat strategii a hledat nové
                                            příležitosti.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <CarouselReference/>
                </div>
            </div>
            <Kontakt/>
        </Layout>
    );
}